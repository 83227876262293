import React, { useEffect, useState} from "react";
import '../../assets/css/container/ScrollTopButton.css';
import { FaArrowUp } from 'react-icons/fa';

const ScrollTopButton = () => {

    const [visible, setVisible ] = useState(false);

    const goTopButton = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    };

    const topScroll = () => {
        let heightToHidden = 350;
        const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;

        if (winScroll > heightToHidden) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll',topScroll );
        return() => window.removeEventListener('scroll', topScroll)
    }, []);

    return (
        <div id="main-btn">
            {visible && (
                <div className="top-btn" onClick={goTopButton}>
                    <FaArrowUp className="scroll-icon" />
                </div>
            )}
        </div>
    )
}

export default ScrollTopButton;