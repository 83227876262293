/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from "react";
import '../assets/css/page/locations.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { FaLocationPin, FaPhone } from "react-icons/fa6";

const Locations = () => {
  const [location1] = useState([
    {
      address: "14234 Smoketown Rd, Woodbridge, VA 22192",
      phoneNumber: "703-490-3112",
      mapSrc:
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3191180.8666582485!2d-77.30122874836948!3d38.63996798437784!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b6566cc84fe667%3A0x36c3ab9431294c53!2sKadi's%20Hair%20Gallery!5e0!3m2!1sen!2sus!4v1712335583165!5m2!1sen!2sus",
    },
  ]);
 const [location2] = useState([
    {
      address: "14849 Buildamerica Dr, Woodbridge, VA 22191",
      phoneNumber: "571-491-9196",
      mapSrc:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3116.8102972181114!2d-77.27539792500922!3d38.630244971782346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b655f0e7deba6b%3A0xbb7021f8cac425c8!2sKadi's%20Hair%20Gallery%202!5e0!3m2!1sen!2sus!4v1712336736934!5m2!1sen!2sus",
    },
  ]);

 return (
  <>
    <HelmetProvider>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{"kadi's hair gallery - locations"}</title>
      </Helmet>
    </HelmetProvider>
        
    <section className='main-locations' id='contact'>
      <div>
        <h2 data-aod="fade-zoom">Store Locations</h2>
          <div id='locations' className='container'>
            <div className='col-sm-6'>
              <h3 className='h3' data-aos="fade-left">
                Kadi's Hair Gallery
              </h3>
              <hr></hr>
                        
              <div className='locations-details'>
                {location1.map((locations1, index) => (
                  <div key={index} className="locations-details">
                    <p><FaLocationPin style={{ marginRight: '6px' }} /> {locations1.address}</p>
                    <p><FaPhone style={{ marginRight: '6px' }} />{locations1.phoneNumber}</p>
                  </div>
                ))}

                <div className="map-container">
                  <iframe
                    src={location1[0].mapSrc}
                    width="100%"
                    height="400"
                    style={{ border: "0" }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>

            <div className='col-sm-6'>
              <h3 className='h3' data-aos='fade-right'>
                Kadi's Hair Gallery 2
              </h3>
              <hr></hr>
                  
              <div className='locations-details'>
                {location2.map((locations2, index) => (
                  <div key={index} className="locations-details">
                    <p><FaLocationPin style={{ marginRight: '6px' }} />{locations2.address}</p>
                    <p><FaPhone style={{ marginRight: '6px' }} />{locations2.phoneNumber}</p>
                  </div>
                ))}
                            
                <div className='location2-map'>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3116.8102972181114!2d-77.27539792500922!3d38.630244971782346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b655f0e7deba6b%3A0xbb7021f8cac425c8!2sKadi's%20Hair%20Gallery%202!5e0!3m2!1sen!2sus!4v1712336736934!5m2!1sen!2sus"
                    width="100%"
                    height="400"
                    style={{ border: "0" }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
  </>
)}
export default Locations;