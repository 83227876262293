import React from "react";
import '../assets/css/page/about.css';
import { Helmet, HelmetProvider } from "react-helmet-async";
import AboutBg from '../assets/img/about-bg.jpg';
//import { Container } from 'react-bootstarp';
import AboutInfo from "../components/Container/About/aboutInfo";
import WhyChooseUs from "../components/Container/About/whychooseus";
import MissionNdValues from "../components/Container/About/missionNdvalues";

const About = () => {
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{"Kadi's Hair Gallery - About us"}</title>
                </Helmet>
            </HelmetProvider>

            <section className="about-main" style={{ backgroundImage: `url(${AboutBg})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="about-content">
                            <h1 className="about-heading">About Us</h1>
                                <ul className="about-path">
                                    <li>
                                        <a href="/">Home</a>
                                    </li>
                                    <li className="active">About</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <AboutInfo />

            <WhyChooseUs />

            <MissionNdValues />
        </>
    )
}

export default About;