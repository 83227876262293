import React from "react";
import { FaCut, FaHandsHelping, FaHome, FaMapMarkedAlt, FaStar, FaUserFriends } from "react-icons/fa";
import '../../../assets/css/container/whychooseus.css';

const WhyChooseUs = () => {

    // array for the reason people should choose the salon
    const reasons = [
        
        {
            title: 'Expert Stylists',
            description: 'Skilled stylists offer personalized and high-quality services.',
            icon: <FaCut />,
        },

        {
            title: 'Welcoming Atmosphere',
            description: 'Enjoy a warm, family-oriented salon environment.', 
            icon: <FaHome />,
        },

        {
            title: 'Comprehensive Services',
            description: 'A diverse array of services for all your hair needs.',
            icon: <FaUserFriends />,
        }, 

        {
            title: 'Community Involvement',
            description: 'Supporting and empowering the local community.',
            icon: <FaHandsHelping />,
        }, 

        {
            title: 'Positive Reputation',
            description: 'Strong reptuation for excellent service and results.',
            icon: <FaStar />,
        },

        {
            title: 'Convenient Locations',
            description: 'Easily accessible locations in Woodbridge, Virginia',
            icon: <FaMapMarkedAlt />,
        },
    ];

    return (
        <div className="container">
          {/* Main container for the component */}
          <div className="why-choose-us">
            {/* Introductory section for the component */}
            <div className="intro">
              <h2>Why People Choose Us</h2>
              <p>Discover what makes our salons the preferred choice for so many clients. We combine expertise, atmosphere, and community values to offer you an unparalleled experience.</p>
            </div>
    
            {/* Section to display reasons in a card layout */}
            <div className="reasons">
              {reasons.map((reason, index) => (
                <div key={index} className="reason-card">
                  {/* Icon for the reason */}
                  <div className="icon">{reason.icon}</div>
                  {/* Title of the reason */}
                  <h3>{reason.title}</h3>
                  {/* Description of the reason */}
                  <p>{reason.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
    );
}

export default WhyChooseUs;