import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import kadiProfilePic from '../../../assets/img/kadi-profile.jpg';

const useStyles = makeStyles((theme) => ({
    kadiInfoContainer: {
        width: '100%',
        backgroundColor: '#f7e7b4', // Light gold background color
        padding: '2rem',
        boxSizing: 'border-box',
        '@media (max-width: 767px)': {
            padding: '1rem',
        },
        '@media (max-width: 1024px)': {
            padding: '1.5rem',
        },
    },
    meetKadiContainer: {
        color: 'black',
        fontFamily: 'Roboto Condensed',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1rem',
        '@media (max-width: 767px)': {
            flexDirection: 'column',
            alignItems: 'center',
        },
        '@media (max-width: 1024px)': {
            margin: '1rem 0',
        },
    },
    meetText: {
        fontSize: '1.5rem',
        lineHeight: '1',
        marginRight: '0.5rem',
        '@media (max-width: 1024px)': {
            fontSize: '1.4rem',
        },
    },
    kadiText: {
        fontSize: '4rem',
        lineHeight: '1',
        '@media (max-width: 1024px)': {
            fontSize: '4rem',
        },
    },
    kadiPicContainer: {
        textAlign: 'center',
        marginBottom: '1rem',
        '@media (max-width: 767px)': {
            marginBottom: '0',
        },
        '@media (max-width: 1024px)': {
            padding: '1.5rem',
            alignSelf: 'center',
        },
    },
    image: {
        width: '80%',
        minWidth: '200px',
        maxWidth: '350px',
        height: 'auto',
        border: '5px solid white',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    },
    aboutKadiContainer: {
        padding: '1rem',
        maxWidth: '800px',
        width: '100%',
        boxSizing: 'border-box',
        //textAlign: 'justify',
        '@media (max-width: 767px)': {
            width: '100%',
        },
        '@media (max-width: 1024px)': {
            width: '90%',
        },
    },
    aboutKadi: {
        color: 'black',
        fontFamily: 'Reem Kufi, san-serif',
        fontSize: '16px',
        lineHeight: '1.8',
        '@media (max-width: 767px)': {
            fontSize: '20px', 
            lineHeight: '2.0rem', 
        },
        '@media (max-width: 1024px)': {
            fontSize: '20px',
            lineHeight: '2.2rem'
        },
    },
    gridItem: {
        width: '100%',
        '@media (min-width: 600px)': {
            maxWidth: '100% ', // Ensure full width on smaller screens
        },
        '@media (max-width: 599px)': {
            width: '100%',
        },
    },
    gridContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        '@media (max-width: 767px)': {
            flexDirection: 'column',
        },
        '@media (max-width: 1024px)': {
            flexDirection: 'column',
        },
    },
}));

const AboutInfo = () => {
    const classes = useStyles();

    return (
        <Container className={classes.kadiInfoContainer}>
            <div className={classes.meetKadiContainer}>
                <h4 className={classes.meetText}>M E E T</h4>
                <h4 className={classes.kadiText}>Kadi</h4>
            </div>
            <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
                className={classes.gridContainer}
            >
                <Grid item xs={12} sm={6} className={classes.kadiPicContainer}>
                    <img
                        src={kadiProfilePic}
                        className={classes.image}
                        alt="Owner of Kadi's Hair Gallery"
                    />
                </Grid>

                <Grid item xs={12} sm={6} className={classes.gridItem}>
                    <div className={classes.aboutKadiContainer}>
                        <Typography className={classes.aboutKadi}>
                            Madam Kadiatu Sesay, known as Kadi, was born in Kissy, Freetown, Sierra Leone, where her passion for community service began. She moved to the United States in 1996 and continued her dedication to helping others, earning recognition for her philanthropic efforts.
                            <br/>
                            <br />
                            As a business entrepreneur, Kadi has made significant contributions to the hair salon and shipping industries, creating jobs and providing opportunities for both immigrants and Americans. Her salons are vital community hubs that offer internships and training for cosmetology students, particularly in partnership with Garfield High School in Woodbridge, Virginia.
                            <br/>
                            <br/>
                            Kadi is also a mentor and coach, supporting young people in their career and educational pursuits. Her philanthropic work extends to Sierra Leone and other African countries, where she provides scholarships, financial aid, and educational resources to schools, including current initiatives in Kasiri and Calaba Town.
                            <br />
                            <br/>
                            Kadi's Hair Gallery, located in Northern Virginia, is not just a salon but a community center that embodies her commitment to excellence and service. The salon offers a wide range of hair and beauty services and serves as a training ground for aspiring cosmetologists.
                            <br />
                            <br/>
                            Through her business ventures and charitable work, Kadi continues to uplift and inspire her community, making a lasting impact both locally and abroad.
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
};

export default AboutInfo;
