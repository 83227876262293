import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./components/Header/header";
import HomeBanner from "./components/Container/Home";
import ScrollTopButton from "./components/Container/ScrollTopButton";
import Footer from "./components/Container/footer";

/** Pages */
import Locations from "./page/locations";
import Contact from "./page/contact";
import About from "./page/about";

import Gentlemen from "./components/Services/gentlemen";
import Ladies from "./components/Services/ladies";

import "./App.css";

function App() {
    return (
        <React.Fragment>
            <BrowserRouter>
                <Header />
                <Routes>
                    <Route exct path="/" element={<HomeBanner />} />

                    <Route path="/about" element={<About />} />
                                      
                    <Route path="/gentlemen" element={<Gentlemen />} />
                    <Route path="/ladies" element={<Ladies />} />

                    <Route path="/locations" element={<Locations />} />  

                    <Route path='/contact' element={<Contact />} />

                </Routes>
            <ScrollTopButton />
            <Footer />
            </BrowserRouter>
        </React.Fragment>
    )
}

export default App;