/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { FaInstagram, FaFacebook } from 'react-icons/fa'; 

import '../assets/css/page/contact.css';

const Contact = () => {
    useEffect(() => {
        Aos.init({ duration: 3000 });
    }, []);

    return (
        <>
        <HelmetProvider>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{"kadi's hair gallery - contact us"}</title>
            </Helmet>
        </HelmetProvider>
            <section className='main-contact' id='contact'>
                <div>
                    <h2 data-aod="fade-zoom">Contact</h2>
                    <div id='contact-us' className='container'>
                        <div className='col-sm-6'>
                            <h3 className='h3' data-aos="fade-left">
                                Store Information
                            </h3>
                            <hr></hr>
                            <ul className='ul'>
                                <li>
                                    <strong>Kadi's Hair Gallery </strong> <br/>
                                    <strong>Business Hours:</strong> <br />
                                    Monday: 10am - 5pm <br />
                                    Tuesday: 9am - 9pm <br />
                                    Wednesday: 9am - 9pm <br />
                                    Thursday: 9am - 9pm <br />
                                    Friday: 9am - 9pm <br />
                                    Saturday: 8am - 5pm <br />
                                    Sunday: Closed <br />
                                </li>

                                <li>
                                    <strong>Kadi's Hair Gallery 2 </strong> <br />
                                    <strong>Business Hours:</strong> <br />
                                    Monday: 9am - 7pm <br />
                                    Tuesday: 9am - 7pm <br />
                                    Wednesday: 9am - 7pm <br />
                                    Thursday: 9am - 7pm <br />
                                    Friday: 9am - 7pm <br />
                                    Saturday: 8am - 5pm <br />
                                    Sunday: Closed <br />
                                </li>

                                <li>
                                    <strong>Follow us</strong>
                                    <span className='icons'>
                                        <a
                                            href='https://www.instagram.com/kadishairgallery/'
                                            target='_blank'
                                            rel="noopener noreferrer"
                                            aria-label='Instagram'
                                        >
                                            <FaInstagram />
                                        </a>
                                    </span>
                                    <span className='icons'>
                                        <a
                                            href='https://www.facebook.com/kadishairgallery/'
                                            target='_blank'
                                            rel="noopener noreferrer"
                                            aria-label='Instagram'
                                        >
                                            <FaFacebook />
                                        </a>
                                    </span>
                                </li>
                            </ul>
                        </div>

                        <div className='col-sm-6'>
                            <h3 className='h3' data-aos='fade-right'>
                                Send Query
                            </h3>
                            <hr></hr>
                            <form
                                className='list'
                                action='https://formspree.io/f/mgegperw'
                                method='POST'
                            >
                                <li>
                                    {" "}
                                    <input
                                        type="text"
                                        name="user"
                                        placeholder="Name"
                                        className="form-control"
                                        required
                                        autoComplete="off"  
                                    />{" "}
                                </li>
                                <li>
                                    {" "}
                                    <input
                                        type="text"
                                        name="email"
                                        placeholder="Email"
                                        className="form-control"
                                        required
                                        autoComplete="off"
                                    />{" "}
                                </li>
                                <li>
                                    {" "}
                                    <input
                                        type="text"
                                        name="contact"
                                        placeholder="Contact"
                                        className="form-control"
                                        required
                                        autoComplete="off"
                                    />{" "}
                                </li>
                                <li>
                                    {" "}
                                    <textarea
                                        type="textarea"
                                        name="Message"
                                        cols="20"
                                        rows="4"
                                        style={{ resize: "none" }}
                                        placeholder="Message"
                                        className="form-control"
                                        required
                                        autoComplete="off"
                                    ></textarea>{" "}
                                </li>
                                <div className='contact-button'>
                                    {" "}
                                    <button className='contact-btn' type="submit">
                                        Send Message
                                    </button>{" "}
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='maps-container'>
                    <div className='location1-map'>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3191180.8666582485!2d-77.30122874836948!3d38.63996798437784!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b6566cc84fe667%3A0x36c3ab9431294c53!2sKadi's%20Hair%20Gallery!5e0!3m2!1sen!2sus!4v1712335583165!5m2!1sen!2sus"
                            width="90%"
                            height="200"
                            style={{ border: "0" }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>

                    <div className='location2-map'>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3116.8102972181114!2d-77.27539792500922!3d38.630244971782346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b655f0e7deba6b%3A0xbb7021f8cac425c8!2sKadi's%20Hair%20Gallery%202!5e0!3m2!1sen!2sus!4v1712336736934!5m2!1sen!2sus"
                            width="90%"
                            height="200"
                            style={{ border: "0" }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </div>
                </div>
            </section>
        </>
    )
}

export default Contact;