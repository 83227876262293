import React from 'react';
import '../../../assets/css/container/missionNdvalues.css';
import { FaStar, FaUsers, FaBalanceScale, FaRocket, FaHeart, FaSmile } from 'react-icons/fa';

const MissionNdValues = () => {
    return (
        <div className="outer-container">
          <div className="mission-values-container">
            <div className="mission-vision-row">
              <section className="mission-section">
                <h2>Our Mission</h2>
                <p>
                  At Kadi's, we enhance beauty and confidence through high-quality, personalized hair care in a welcoming environment. We empower the community by offering education, employment, and mentorship in the beauty industry.
                </p>
              </section>
    
              <section className="vision-section">
                <h2>Our Vision</h2>
                <p>
                  Our vision is to be Northern Virginia's leading beauty salon, known for exceptional hair care and community impact. We aim to inspire creativity and empower our clients and team to achieve their best.
                </p>
              </section>
            </div>
    
            <section className="values-section">
              <h2>Our Values</h2>
              <div className="values-grid scrollable">
                <div className="value-item">
                  <FaStar className="value-icon" />
                  <strong>Excellence</strong>
                  <p>We aim for the highest standards in hair care.</p>
                </div>
                <div className="value-item">
                  <FaUsers className="value-icon" />
                  <strong>Community</strong>
                  <p>We support local initiatives and create opportunities.</p>
                </div>

                <div className="value-item">
                  <FaBalanceScale className="value-icon" />
                  <strong>Integrity</strong>
                  <p>We build trust through honesty and transparency.</p>
                </div>
                <div className="value-item">
                  <FaRocket className="value-icon" />
                  <strong>Empowerment</strong>
                  <p>We promote growth through continuous learning.</p>
                </div>
                <div className="value-item">
                  <FaHeart className="value-icon" />
                  <strong>Inclusivity</strong>
                  <p>We embrace diversity and ensure everyone feels valued.</p>
                </div>
                <div className="value-item">
                  <FaSmile className="value-icon" />
                  <strong>Customer Satisfaction</strong>
                  <p>We prioritize client satisfaction with personalized care.</p>
                </div>
              </div>
            </section>
          </div>
        </div>
      );
    };

export default MissionNdValues;

