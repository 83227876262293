import React, {useState, useEffect} from "react";
import '../../assets/css/header/navbar.css';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { CgMenu, CgClose } from 'react-icons/cg';

const Navbar = () => {

    //const [aboutNav, setAboutNav ] = useState(false);
    const [openNav, setOpenNav] = useState(false);
    const [serviceNav, setServiceNav] = useState(false);

    // Function to toggle the dropdown
    /** 
    const toggleDropdown = () => {
        setAboutNav(!aboutNav);
    };
    */

    const toggleServiceDropdown = () => {
        setServiceNav(!serviceNav);
    };

    return (
        <section id="main_nav">
            <nav className={openNav ? "active" : ""}>
                <ul className="nav_list">
                    <li className="nav-item">
                        <HashLink className="nav_link" to="/#" onClick={() => setOpenNav(false)}> Home </HashLink>
                    </li>

                    <li className="nav-item">
                        <HashLink className="nav_link" to="/about" onClick={() => setOpenNav(false)}> About </HashLink>
                    </li>

                    <li className="nav-item dropdown">
                        <Link to="/#services" className="nav_link dropdown-toggle hover" role='dropdown' data-bs-toggle="dropdown" aria-expanded="false"id="link_dropdown"> Services </Link>
                        <ul className={`dropdown-menu ${serviceNav ? 'show' : ''}`} aria-labelledby="link_dropdown">
                            <li>
                                <Link id="link_ladies" className="dropdown-item" to="/ladies" onClick={() => { setOpenNav(false); setServiceNav(false); }}> Ladies </Link>
                            </li>
                            <li>
                                <Link id="link_gentlemen" className="dropdown-item" to="/gentlemen" onClick={() => { setOpenNav(false); setServiceNav(false); }}> Gentlemen </Link>
                            </li>
                        </ul>
                    </li>

                    <li className="nav-item">
                        <HashLink className="nav_link" to="/locations" onClick={() => setOpenNav(false)}> Locations </HashLink>
                    </li>

                    <li className="nav-item">
                        <HashLink className="nav_link" to="/contact" onClick={() => setOpenNav(false)}> Contact </HashLink>
                    </li>
                </ul>

                <div className="mobile_nav_button">
                    <CgMenu name="menu-outline" className="mobile_nav_icon" onClick={() => setOpenNav(true)} />
                    <CgClose name="close-outline" id="close_outline" className="mobile_nav_icon" onClick={() => setOpenNav(false)} />
                </div>
            </nav>
        </section>
    );
}

export default Navbar;