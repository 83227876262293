import React from 'react';
import '../../../assets/css/container/whatweoffer.css';

//offers
import Braiding from '../../../assets/img/offers/braiding.jpg';
import Styling from '../../../assets/img/offers/styling.jpg';
import Coloring from '../../../assets/img/offers/coloring.jpg';
import Extension from '../../../assets/img/offers/extension.jpg';
import Treatment from '../../../assets/img/offers/treatment.jpg';
import Men from '../../../assets/img/offers/men.jpg';

const WhatWeOffer = () => {
    return (
      <div className="what-we-offer-container">
        <h2>What We Offer at Kadi's</h2>
        <p>
          At Kadi's, we offer a variety of high-quality hair care services tailored to enhance your natural beauty in a welcoming environment.
        </p>
  
        <div className="service-category">
          <div className="service-image">
            <img src={Braiding} alt="Hair Braiding" />
          </div>
          <h3>Hair Braiding </h3>
        </div>
  
        <div className="service-category">
          <div className="service-image">
            <img src={Styling} alt="Hair Styling" />
          </div>
          <h3>Hair Styling </h3>
        </div>
  
        <div className="service-category">
          <div className="service-image">
            <img src={Coloring} alt="Hair Coloring" />
          </div>
          <h3>Hair Coloring </h3>
        </div>
  
        <div className="service-category">
          <div className="service-image">
            <img src={Extension} alt="Hair Extensions" />
          </div>
          <h3>Hair Extensions and Weaves</h3>
        </div>
  
        <div className="service-category">
          <div className="service-image">
            <img src={Treatment} alt="Hair Treatments" />
          </div>
          <h3>Specialized Hair Treatments</h3>
        </div>
  
        <div className="service-category">
          <div className="service-image">
            <img src={Men} alt="Men's Grooming" />
          </div>
          <h3>Men's Grooming </h3>
        </div>
      </div>
    );
  };
export default WhatWeOffer;
