import React, { useEffect } from "react";
import Aos from "aos";
import 'aos/dist/aos.css';
import { HashLink } from "react-router-hash-link";
import Header1 from '../../assets/img/header1.png';
import { FaFacebook, FaInstagram } from "react-icons/fa";
import '../../assets/css/container/footer.css';

const Footer = () => {

    useEffect(() => {
        Aos.init({ duration: 3000 })
    }, []);

    return (
        <section id="main-footer">
            <footer className="container">
                <div>
                    <div className="footer-img" data-aos='fade-up'>
                        <img src={Header1} alt="footer-logo" className="img-logo" />
                    </div>

                    <div className="nav-grid">
                        <ul className="nav-list">
                            <li>
                                <HashLink className="nav-link" to='/'> Home </HashLink>
                            </li>
                            <li>
                                <HashLink className="nav-link" to='/about'> About </HashLink>
                            </li>
                            <li>
                                <HashLink className="nav-link" to='/locations'> Locations </HashLink>
                            </li>
                            <li>
                                <HashLink className="nav-link" to='/contact'> Contact </HashLink>
                            </li>
                        </ul>
                    </div>

                    <div className="social-grid">
                        <ul className="icons">
                            <li><a href='https://www.instagram.com/kadishairgallery/' data-aos='fade-zoom' className="icons-color" id="insta-icon"><FaInstagram /></a></li>
                            <li><a href='https://www.facebook.com/kadishairgallery/' data-aos='fade-zoom' className="icons-color" id="facebook-icon"><FaFacebook /></a></li>
                        </ul>
                    </div>

                    <div className="text-grid">
                        <p style={{textDecoration:"none",color:"white"}}> Call us either at (703)490-3112 (Location 1) <strong>or</strong> (571)491-9196 (Location 2) </p>
                    </div>
                    <hr></hr>

                    <div className="footer-bottom">
                        <div>
                            <p>@{new Date().getFullYear()} Kadi's Hair Gallery. All Right Reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </section>
    )
}

export default Footer;