import { Link } from "react-router-dom";
import Navbar from "./navbar.js";
import '../../assets/css/header/header.css';
import Header2 from '../../assets/img/header2.png'
import Header1 from '../../assets/img/header1.png'

const Header = () => {

    return (
        <section id="mainHeader">
            <Link to="/">
                <img src={Header1} alt="header-logo" className="imgLogo" />
            </Link>
            <Navbar />
        </section>
    )
}

export default Header;