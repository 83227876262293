import React from "react";
import { useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import Aos from "aos";
import 'aos/dist/aos.css';

import ServiceImg1 from '../../assets/img/service-img1.png';
import ServiceImg2 from '../../assets/img/service-img2.png';

import '../../assets/css/container/services.css';
const Services = () => {

    useEffect(() => {
        Aos.init({ duration:3000 })
    }, [])

    return (
        <section className="main-service" id="services">
            
            <div className="heading"><h3>Our Services</h3></div>
            <div className="service-imgs">

                <div className="img1">
                    <HashLink smooth to='/ladies#'>
                        <img src={ServiceImg1} alt="services-img1" data-aos="fade-right" />
                        <span className="span1" data-aos='fade-right'>LADIES</span>
                    </HashLink>
                </div>

                <div className="img1">
                    <HashLink smooth to='/gentlemen#'>
                        <img src={ServiceImg2} alt="services-img2" data-aos="fade-left" />
                        <span className="span2" data-aos='fade-left'>GUYS</span>
                    </HashLink>
                </div>
            </div>
        </section>
    )
}

export default Services;