import React from "react";
import '../../assets/css/container/makeappointment.css';

const MakeAppointment = () => {
    return (
        <section className="section-sm context-dark bg-secondary">
            <div className="make-appointment-container">
                <div className="make-appointment">
                    <p className="contact-info">
                        To Book an appointment, Call or Text <a href="tel:5714919196">(571) 491-9196</a>
                    </p>
                </div>
            </div>
        </section>
    );
};

export default MakeAppointment;
