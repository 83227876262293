import React from "react";
import '../../../assets/css/container/introductory.css';

const Introductory = () => {

    return (
        <div className="container">
          <header className="header">
            <h1>Welcome to Kadi's Hair Gallery</h1>
          </header>
          <main className="main-content">
            <p>
              At Kadi's Hair Gallery, we transform salon visits into uplifting experiences. Our vibrant salons are where beauty, confidence, and creativity flourish. Whether it's a bold new style or a classic cut, our talented team brings your vision to life.
            </p>
            <p>
              We pride ourselves on excellence and inclusivity, offering diverse services from intricate braiding to vibrant colors. Join our community and see why we’re Northern Virginia’s go-to destination for hair and beauty!
            </p>
          </main>
        </div>
      );
    };

export default Introductory;
