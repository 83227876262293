import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { HashLink } from "react-router-hash-link";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

import '../../assets/css/container/services/gentlemen.css';

import Gentlemen1 from '../../assets/img/gentlemen.jpg';
import Gentlemen2 from '../../assets/img/gentlemen1.jpg';

const Gentlemen = () => {

    const gentServices = [
        { name: 'Regular Haircut', price: 30 },
        { name: 'Haircut + Beard', price: 40 },
        { name: 'Fades', price: 35 },
        { name: 'Shape Up', price: 25 },
        { name: 'Beard Trim', price: 20 },
        { name: 'Kids Cuts (under 9 years and below)', price: 25 },
        { name: 'Hairline Trim', price: 15 },
        { name: 'Taper', price: 35 },
        { name: 'Ladies Cut', price: 35 },
        /** Additional services
        { name: 'Hot Towel Shave', price: 50 },
        { name: 'Buzz Cut', price: 20 },
        { name: 'Line Up', price: 15 },
        { name: 'Scissor Cut', price: 45 },
        { name: 'Head Shave', price: 30 },
        { name: 'Senior Haircut (65+)', price: 20 },
        { name: 'Military Haircut', price: 25 },
        { name: 'Black Mask Facial', price: 30 },
        { name: 'Beard Coloring', price: 40 },
        */
    ];
    
    return (
        <HelmetProvider>
        <section className="main_banner">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{"Kadi's Hair Gallery"}</title>
            </Helmet>

            <div className="home_container">
                <Swiper
                    modules = {[Navigation, Pagination, Scrollbar, Autoplay]}
                    spaceBetween = {1}
                    centeredSlides = {true}
                    loop = {true}
                    autoplay = {{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    navigation = {true}
                    pagination = {{ clickable: true }}
                >
                    <SwiperSlide>
                        <img 
                            src={Gentlemen1}
                            alt="banner_1"
                            style={{ filter: 'blur(1px)' }}
                            className="home_img"
                        />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img 
                            src={Gentlemen2}
                            alt="banner21"
                            className="home_img"
                        />
                    </SwiperSlide>
                </Swiper>
        <div className="gentlemen-container" id="gentlemen">

            <p className="gent-heading">
                <HashLink to='/ladies#' className="ladies-gent-heading">
                    {" "}
                    Go to the Ladies Services{" "}
                </HashLink>
            </p>

          <h1>Men's Services</h1>
          <div className="gentlemen-list">
            {gentServices.map((gentService, index) => (
              <div key={index} className="gentlemen-item">
                <span className="service-name">{gentService.name}</span>
                <span className="service-price">${gentService.price}</span>
              </div>
            ))}
          </div>
          <div className="contact">
            <p>Contact us at: <strong>(703)-334-1368</strong></p>
          </div>
          </div>
        </div>
        </section>
        </HelmetProvider>
    );
};

export default Gentlemen;