import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';
import { Helmet, HelmetProvider } from "react-helmet-async";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

import Banner1 from '../../assets/img/Banner.png';
import Banner2 from '../../assets/img/Banner2.png';

import MakeAppointment from "./MakeAppointment";
import Services from "./services";
import Introductory from "./Home/introductory";
import Gallery from "./Home/gallery";
import WhatWeOffer from "./Home/whatweoffer";
import Policies from "./Home/policies";

import '../../assets/css/container/home.css';



function HomeBanner() {

    return (
        <HelmetProvider>
        <section className="main_banner">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{"Kadi's Hair Gallery"}</title>
            </Helmet>

            <div className="home_container">
                <Swiper
                    modules = {[Navigation, Pagination, Scrollbar, Autoplay]}
                    spaceBetween = {1}
                    centeredSlides = {true}
                    loop = {true}
                    autoplay = {{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    navigation = {true}
                    pagination = {{ clickable: true }}
                >
                    <SwiperSlide>
                        <img 
                            src={Banner1}
                            alt="banner_1"
                            style={{ filter: 'blur(1px)' }}
                            className="home_img"
                        />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img 
                            src={Banner2}
                            alt="banner21"
                            className="home_img"
                        />
                    </SwiperSlide>
                </Swiper>
            </div>

            <MakeAppointment />

            <Introductory />

            <Policies />
            
            <WhatWeOffer />

            <Gallery />

            <Services />
            
        </section>
        </HelmetProvider>
    )
}

export default HomeBanner;